.wrap .van-popup {
  border-radius: 15px;
  background: darkolivegreen;
  padding: 5px;
  width: 70%;
  display: flex;
  flex-direction: column;
}
.wrap .van-popup .share_img {
  width: 100%;
}
.wrap .van-popup span {
  text-align: center;
  font-size: 0.3rem;
}
.wrap .van-popup #qrcode {
  padding: 0.2rem;
}
.op_container {
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  /* 水平居中 */
}
.codeBox {
  width: 2.73rem;
  height: 3rem;
  text-align: center;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  margin: 0 auto;
}
.codeBox img {
  width: 90%;
}
#captureId {
  visibility: hidden;
  float: left;
  margin-top: -10rem;
}
.leftBtn {
  width: 35%;
  margin-left: 10px;
  float: left;
  margin-right: 1rem;
}
.rightBtn {
  width: 35%;
  margin-right: 10px;
  float: right;
}
