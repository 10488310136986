













































































































































































// 我写的less
.wrap {
  // 保存出来的分享图
  .van-popup {
    border-radius: 15px;
    background: darkolivegreen;
    padding: 5px;

    width: 70%;
    display: flex;
    flex-direction: column;
    .share_img {
      width: 100%;
    }
    span {
      text-align: center;
      font-size: 0.3rem;
    }
    #qrcode {
      padding: 0.2rem;
    }
  }
}
.op_container {
  margin: 0 auto;
  text-align: center;
  // flex-direction: row;
  display: flex;
  justify-content: center; /* 水平居中 */
}
.codeBox {
  width: 2.73rem;
  height: 3rem;
  text-align: center;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(229, 229, 229, 1);
  margin: 0 auto;
  img {
    width: 90%;
  }
}

#captureId {
  visibility: hidden;
  float: left;
  margin-top: -10rem;
}
.leftBtn {
  width: 35%;
  margin-left: 10px;
  float: left;
  margin-right: 1rem;
}
.rightBtn {
  width: 35%;
  margin-right: 10px;
  float: right;
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;